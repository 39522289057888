import React , { useState }  from "react"
import banner from '../images/banner.jpg'
import Menu from './menu'

const TopBar = () => {
   // Declare a new state variable, which we'll call "count"  
    const [menuOpen, setMenuOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);

  return (
    <header className="cs-header navbar navbar-expand-lg navbar-light bg-light navbar-box-shadow navbar-sticky navbar-stuck" data-scroll-header>
    <div className={`navbar-search bg-light ${searchOpen?'show':''}`}>
      <div className="container d-flex flex-nowrap align-items-center"><i className="fe-search font-size-xl"></i>
        <form action="/search" method="get" style={{width: '100%'}}>
          <input name="term" className="form-control form-control-xl navbar-search-field" type="text" placeholder="Search site"/>
        </form>
        <div className="d-flex align-items-center"><span className="text-muted font-size-xs mt-1 d-none d-sm-inline">Close</span>
          <button className="close p-2" type="button" data-toggle="search" onClick={() => setSearchOpen(false)} >&times;</button>
        </div>
      </div>
    </div>
    <div className="container px-0 px-xl-3">
      <button className="navbar-toggler ml-n2 mr-4" type="button" onClick={() => setMenuOpen(!menuOpen)} data-toggle="offcanvas" data-offcanvas-id="primaryMenu">
        <span className="navbar-toggler-icon"></span>
      </button>
      <a className="navbar-brand order-lg-1 mx-auto ml-lg-0 pr-lg-2 mr-lg-4" href="/">
        Mathcurious
      </a>
      <div className="d-flex align-items-center order-lg-3 ml-lg-auto">
        <div className="navbar-tool"><a className="navbar-tool-icon-box mr-lg-2" href="#" onClick={() => setSearchOpen(!searchOpen)} data-toggle="search"><i className="fe-search"></i></a></div>
        <div className="d-lg-block d-none border-left mr-4" style={{height: '30px'}}></div>
        <div className="d-lg-block d-none">
          <a className="social-btn sb-outline sb-facebook mr-2" href="https://facebook.com/Mathcurious"><i className="fe-facebook"></i></a>
          <a className="social-btn sb-outline sb-twitter mr-2" href="https://twitter.com/curious_math"><i className="fe-twitter"></i></a>
          <a className="social-btn sb-outline sb-pinterest" href="https://pinterest.com/Mathcurious"><i className="fe-pinterest"></i></a>
        </div>
      </div>
      <div  id="primaryMenu" className={`cs-offcanvas-collapse order-lg-2 ${menuOpen?'show':''} ` }>
        <div className="cs-offcanvas-cap navbar-box-shadow">
          <h5 className="mt-1 mb-0">Menu</h5>
          <button className="close lead" type="button" onClick={() => setMenuOpen(!menuOpen)} data-toggle="offcanvas" data-offcanvas-id="primaryMenu"><span aria-hidden="true">&times;</span></button>
        </div>
        <div className="cs-offcanvas-body">
          
          <Menu />
          
        </div>
        <div className="cs-offcanvas-cap justify-content-center border-top">
          <a className="social-btn sb-lg sb-outline sb-facebook mr-3" href="https://facebook.com/Mathcurious"><i className="fe-facebook"></i></a>
          <a className="social-btn sb-lg sb-outline sb-twitter mr-3" href="https://twitter.com/curious_math"><i className="fe-twitter"></i></a>
          <a className="social-btn sb-lg sb-outline sb-pinterest" href="https://pinterest.com/Mathcurious"><i className="fe-pinterest"></i></a>
          </div>
      </div>
    </div>
  </header>
  
  )
}

export default TopBar
