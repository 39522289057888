
import React, { useState, useCallback, useEffect } from "react"
import {Modal, Button} from 'react-bootstrap'
import Subscribe from './subscribe'

const SubscribePopup = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {        
        if(!localStorage["alreadyVisited"]) {   
            localStorage["alreadyVisited"] = true;         
            const timer = setTimeout(() => {
                setShow(true)    
            }, 10000);                      

            return () => clearTimeout(timer)      
        }                        
    }, [setShow]);

    return (           
      <Modal show={show} onHide={handleClose} animation={true} aria-labelledby="contained-modal-title-vcenter"
      centered >
        <Modal.Header closeButton>
          Subscribe
        </Modal.Header>
        <Modal.Body>
            <Subscribe light={false} footer={true} />
        </Modal.Body>        
      </Modal>
    )    
}

export default SubscribePopup
