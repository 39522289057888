/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const CategorySideBar = () => {
  const  categories  = useStaticQuery(graphql`
    query Category {        
        allWpCategory {
            edges {
            node {
                id
                slug
                name
                count
            }
            }
        }        
    }
  `)
  
  const catList = categories.allWpCategory.edges.map((cat) =>
    <li key={cat.node.id}>
        <a className="cs-widget-link" href={`/category/${cat.node.slug}`}>
        {cat.node.name}<small className="text-muted pl-1 ml-2">{cat.node.count}</small>
        </a>
    </li>    
    );

  return (
    <ul >
      {catList}
    </ul>
  )
}

export default CategorySideBar
