

import React , { useState }  from "react"
import banner from '../images/banner.jpg'

const Menu = () => {
  const [gamesMenuOpen, setGamesMenuOpen] = useState(false);
  return (
    <ul className="navbar-nav">       
        <li className="nav-item"><a className="nav-link" href="/worksheets" >WORKSHEETS</a></li>
        <li className="nav-item"><a className="nav-link" href="/mathematical-picture-and-story-books" >BOOKS</a></li>        
        <li className="nav-item"><a className="nav-link" href="https://app.mathcurious.com/puzzles" >PUZZLES</a></li>
        
        <li className="nav-item dropdown"><a className="nav-link dropdown-toggle" href="#" onClick={() => setGamesMenuOpen(!gamesMenuOpen)} data-toggle="dropdown">GAMES</a>
          <ul className={`dropdown-menu ${gamesMenuOpen?'show':''}`} >
            <li><a className="dropdown-item" href="/category/print-to-play/">PRINT TO PLAY</a></li>
            <li><a className="dropdown-item" href="/category/digital-games/">DIGITAL GAMES</a></li>
          </ul>
        </li>          
        <li className="nav-item"><a className="nav-link" href="/category/task-cards/" >TASK CARDS</a></li>        
        <li className="nav-item"><a className="nav-link" href="https://www.teacherspayteachers.com/Store/Mathcurious" >TPT STORE</a></li>
    </ul>
  )
}

export default Menu
