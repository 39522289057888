
import React, { useState, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import axios from 'axios'

const Subscribe = (props) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleTextChange = useCallback(event => {
    setEmail(event.target.value);
    }, []);

    const clickHandler = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }
        const token = await executeRecaptcha('subscribe');
        try {
            const res = await axios.post(`https://api.mathcurious.com/public-users`, {              
                token : token,
                mail_pref: 'POST' ,
                email: email
            });        
                
            if (res.statusText === 'OK') {
                setMessage(res.data)
            } 
        } catch (err) {            
            setMessage(err.response.data.data.errors.email[0]);
        }
    }, [email, setMessage]);
    if(props.footer) {
        return (           
            <div className={`cs-widget pb-3 mb-4 ${props.light?'cs-widget-light':''}`}>
              <h4 className="cs-widget-title">Subscribe and never miss a post!</h4>
              <div className="form-group" id="subres" style={{color:`${props.light?'white':'red'}`}}>	
                    {message}	
                </div>              
                <div className="input-group input-group-overlay flex-nowrap">
                    <div className="input-group-prepend-overlay"><span className="input-group-text text-muted"><i className="fe-mail"></i></span></div>
                    <input onChange={handleTextChange} className="form-control prepended-form-control" type="email" value={email}  placeholder="Your email"/>
                    <div className="input-group-append">
                    <button className="btn btn-primary" type="submit" name="subscribe"  onClick={clickHandler}>Subscribe*</button>
                    </div>
                </div>                
                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true">
                    <input className="cs-subscribe-form-antispam" type="text" name="b_c7103e2c981361a6639545bd5_29ca296126" tabIndex="-1"/>
                </div><small className={`form-text  pt-1 ${props.light?'text-light opacity-50':''}`}>*Subscribe to our newsletter and be the first to get notified about updates.</small>
                <div className="cs-subscribe-status"></div>              
            </div>                        
        )
    } else {
        return (
            <div className="cs-widget mt-n1 mb-5" >
                <h3 className="cs-widget-title pb-1">Subscribe</h3>
                <div className="form-group" id="subres" style={{color:'red'}}>	
                    {message}	
                </div>
                <div className="form-group">
                    <input className="form-control" type="email" onChange={handleTextChange} value={email} placeholder="Please enter your email address" name="subemail" />
                </div>
                <div className="form-group">
                    <a className="btn btn-success btn-block" onClick={clickHandler} style={{backgroundColor: '#8CB65F'}}>Subscribe</a>			
                </div>		
            </div>
        )
    }
}

export default Subscribe
