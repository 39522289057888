import React , { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import TopBar from './topbar'
import '../scss/theme.scss'
import banner from '../images/banner.jpg'
import chicken_escape_banner_image from '../images/chicken_escape_banner.jpg'
import dragontimes_banner from '../images/dragontimes_banner.jpg'
import CategorySideBar from './category_sidebar'
import Subscribe from "./subscribe"
import ReactTwitterFollowButton from 'react-twitter-follow-button';
import { Page } from 'react-facebook';
import SubscribePopup from "./subscribe_popup"
import SalesPopup from "./sales_popup"
import Logo from "./logo"
import { FacebookProvider } from 'react-facebook';
import {DisplayAdsense} from '../components/adsense'

const Layout = ({ isHomePage, children }) => {
 

  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <div className="cs-is-sidebar">
    <FacebookProvider appId="258572075331216" wait="true">
    <main className="cs-page-wrapper" style={{paddingTop: '70px'}}>
      
      
      <TopBar/>
      <Logo />  
     
     
      
 
      
      

      
      <section className="container" style={{paddingTop: '20px'}}>
        <div className="row">
          
          <div className="col-lg-9">
            
            {children}
            
            
          </div>
          
          <aside className="col-lg-3">
            <div className="" id="blog-sidebar">             
              <div className=" px-4 pt-3 pt-lg-0 pr-lg-0 pl-lg-2 pl-xl-4" data-simplebar>                
                <Subscribe />
                
                <div className="cs-widget mt-n1 mb-5" >
                  <h3 className="cs-widget-title pb-1">Follow us on Facebook</h3>
                  <div style={{width: '261px', height: '130px'}}>
                    <Page href="https://www.facebook.com/Mathcurious"  adaptContainerWidth={true} showFacepile={true}/>
                  </div>
                </div>

                <DisplayAdsense slot={6992984225} />

                <div className="cs-widget mt-n1 mb-5" >
                  <h3 className="cs-widget-title pb-1">Follow us on Twitter</h3>
                  <ReactTwitterFollowButton twitterAccount="curious_math" showLarge={true} showName={true} showCount={true} />
                </div>  

                <div className="cs-widget cs-widget-categories mb-5">
                  <h3 className="cs-widget-title">Categories</h3>
                  <CategorySideBar />                  
                </div>

                <div>
                    <p className="pis-title"><a className="pis-title-link" href="/2020/05/23/chicken-escape/" rel="bookmark">Chicken Escape</a></p>
                    <p className="pis-excerpt">
                      <a className="pis-thumbnail-link" href="/2020/05/23/chicken-escape/" rel="bookmark">
                        <img width="300" height="167" src={chicken_escape_banner_image} className="pis-thumbnail-img aligncenter wp-post-image" alt=""  sizes="(max-width: 300px) 100vw, 300px"/>
                      </a>
                      A multiplayer card game that makes mental math practice fun! Chicken Escape is a fast-paced multiplayer card game. While playing… <span className="pis-more">
                      <a className="pis-more-link" href="/2020/05/23/chicken-escape/" rel="bookmark">Read more…</a></span></p>
                </div>
                <div>
                  <p className="pis-title"><a className="pis-title-link" href="/2020/05/13/dragon-times-a-math-adventure-card-game/" rel="bookmark">Dragon Times – A math Adventure card game</a></p>
                  <p className="pis-excerpt">
                    <a className="pis-thumbnail-link" href="/2020/05/13/dragon-times-a-math-adventure-card-game/" rel="bookmark">
                      <img width="300" height="167" src={dragontimes_banner} className="pis-thumbnail-img aligncenter wp-post-image" alt=""  sizes="(max-width: 300px) 100vw, 300px"/>
                    </a>
                    Dragon Times is an educational fantasy card game that aims to motivate children to practice multiplication and division facts while… 
                    <span className="pis-more"><a className="pis-more-link" href="/2020/05/13/dragon-times-a-math-adventure-card-game/" rel="bookmark">Read more…</a></span>
                    </p>
                </div>  
                
                
              
                
                <div className="mb-5">
                  <h3 className="cs-widget-title pb-1">Follow Us</h3>
                  <a className="social-btn sb-outline sb-facebook mr-2 mb-2" href="https://facebook.com/Mathcurious"><i className="fe-facebook"></i></a>
                  <a className="social-btn sb-outline sb-twitter mr-2 mb-2" href="https://twitter.com/curious_math"><i className="fe-twitter"></i></a>
                  <a className="social-btn sb-outline sb-pinterest mr-2 mb-2" href="https://pinterest.com/Mathcurious"><i className="fe-pinterest"></i></a>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </section>
    </main>
    
    <footer className="cs-footer bg-dark pt-5 pt-md-6">
      <div className="container pt-3 pb-0 pt-md-0 pb-md-3">
        <div className="row mb-4">
          <div className="col-md-4">
            <div className="cs-widget cs-widget-light pb-2 mb-4">
              <h4 className="cs-widget-title">Contacts</h4>
              <ul>
                <li><a className="cs-widget-link" href="mailto:admin@mathcurious.com"><i className="fe-mail font-size-lg mr-2"></i>admin@mathcurious.com</a></li>                
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="cs-widget cs-widget-light pb-2 mb-4">
              <h4 className="cs-widget-title">Follow</h4>
              <a className="social-btn sb-outline sb-lg sb-light sb-facebook mr-2" href="https://facebook.com/Mathcurious"><i className="fe-facebook"></i></a>
              <a className="social-btn sb-outline sb-lg sb-light sb-twitter mr-2" href="https://twitter.com/curious_math"><i className="fe-twitter"></i></a>              
              <a className="social-btn sb-outline sb-lg sb-light sb-pinterest" href="https://pinterest.com/Mathcurious"><i className="fe-pinterest"></i></a>
            </div>
          </div>
          <div className="col-md-4">
            <Subscribe footer={true} light={true}/>                        
            <SubscribePopup />
          </div>
        </div>
        <p className="font-size-sm mb-3 order-md-1">
          <span className="text-light opacity-50 mr-1">&copy; Mathcurious.com All rights reserved. </span>
         |
          <a href="/terms-of-use">Terms of service and use</a>
        </p>
      </div>
    </footer>
        
    <a className="btn-scroll-top" href="#top" data-scroll><span className="btn-scroll-top-tooltip text-muted font-size-sm mr-2">Top</span><i className="btn-scroll-top-icon fe-arrow-up">   </i></a>
    </FacebookProvider>
  </div> 
  )
}

export default Layout
