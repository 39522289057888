import React , { useEffect } from "react";

export const Adsense = ({ slot,layout, format = 'auto', layoutKey}) => {  
    useEffect(() => {    
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);
   
  return (
    <div>
      <div className="separator">Advertisement</div>
    <ins className="adsbygoogle" style={{display:'block', textAlign:'center'}}
    data-ad-layout={layout}    
    data-ad-client="ca-pub-3566627190639385"
    data-ad-slot={slot}
    data-ad-layout-key={layoutKey}
    data-ad-format={format}></ins>
    <div className="separator"></div>
    </div>
  )
}



export const DisplayAdsense = ({ slot }) => {  
  useEffect(() => {    
      (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);
 
return (
    <div>
      <ins class="adsbygoogle"
        style={{display:'block'}}
        data-ad-client="ca-pub-3566627190639385"
        data-ad-slot={slot}
        data-ad-format="auto"
        data-full-width-responsive="true">
      </ins>
    </div>
  )
}
